<template>
  <div v-if="isLoaded">
    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
        <div class="row">
          <div class="offset-xxl-2 col-xxl-8">
            <h4 class="mb-10 font-weight-bold text-dark">
              Hinzugefügte Optionen
            </h4>
            <SubscriptionPropertyLine
              name="Hinzugefügt am"
              :property="formatDateTime(subscriptionOptionsOrder.added_date)"
            />
            <SubscriptionPropertyLine
              name="Firmenlogo hinzugefügt"
              :property="
                subscriptionOptionsOrder.has_logo_added ? 'Ja' : 'Nein'
              "
            />
            <SubscriptionPropertyLine
              name="Anzahl Niederlassungen hinzugefügt"
              :property="subscriptionOptionsOrder.nr_of_branch_offices_added"
            />
            <SubscriptionPropertyLine
              name="Anzahl BKP Nummern hinzugefügt"
              :property="subscriptionOptionsOrder.nr_of_additional_bkp_added"
            />
            <SubscriptionPropertyLine
              name="Firmenportrait hinzugefügt"
              :property="
                subscriptionOptionsOrder.has_portrait_added ? 'Ja' : 'Nein'
              "
            />

            <!--            <div class="border-bottom mt-10" />-->

            <!--            <h4 class="mb-10 mt-10 font-weight-bold text-dark">-->
            <!--              Basisabonement-->
            <!--            </h4>-->

            <!--            <SubscriptionPropertyLine-->
            <!--              name="Abgeschlossen am"-->
            <!--              :property="formatDateTime(subscriptionOrder.start_date)"-->
            <!--            />-->
            <!--            <SubscriptionPropertyLine-->
            <!--              name="Endet am"-->
            <!--              :property="formatDateTime(subscriptionOrder.end_date)"-->
            <!--            />-->
            <!--            <SubscriptionPropertyLine-->
            <!--              name="Vertragslaufzeit"-->
            <!--              :property="subscriptionOrder.nr_of_years"-->
            <!--              postfix="Jahre"-->
            <!--            />-->

            <div class="border-bottom mt-10" />

            <h4 class="mb-10 mt-10 font-weight-bold text-dark">
              Preise
            </h4>
            <SubscriptionPropertyLine
              name="Firmenlogo"
              prefix="CHF"
              :property="formatFloat(subscriptionOptionsOrder.logo_price)"
            />
            <SubscriptionPropertyLine
              name="Niederlassungen"
              prefix="CHF"
              :property="
                formatFloat(subscriptionOptionsOrder.branch_office_price)
              "
            />
            <SubscriptionPropertyLine
              name="Zusätzliche BKP Nummern"
              prefix="CHF"
              :property="
                formatFloat(subscriptionOptionsOrder.additional_bkp_price)
              "
            />
            <SubscriptionPropertyLine
              name="Firmenportrait"
              :property="
                'CHF ' + formatFloat(subscriptionOptionsOrder.portrait_price)
              "
            />

            <div class="border-bottom mt-10" />

            <h4 class="mb-10 mt-10 font-weight-bold text-dark">
              Kosten
            </h4>
            <SubscriptionPropertyLine
              name="Kosten Logo"
              prefix="CHF"
              :property="formatFloat(subscriptionOptionsOrder.total_logo_price)"
            />
            <SubscriptionPropertyLine
              name="Kosten Niederlassungen"
              prefix="CHF"
              :property="
                formatFloat(subscriptionOptionsOrder.total_branch_offices_price)
              "
            />
            <SubscriptionPropertyLine
              name="Kosten zusätzliche BKP Nummern"
              prefix="CHF"
              :property="
                formatFloat(subscriptionOptionsOrder.total_additional_bkp_price)
              "
            />
            <SubscriptionPropertyLine
              name="Kosten Firmenportrait"
              :property="
                'CHF ' +
                  formatFloat(subscriptionOptionsOrder.total_portrait_price)
              "
            />
            <SubscriptionPropertyLine
              name="Total hinzugefügte Optionen"
              prefix="CHF"
              :property="
                formatFloat(subscriptionOptionsOrder.total_options_price)
              "
              :isBold="true"
            />

            <div class="pt-5" />

            <SubscriptionPropertyLine
              name="Vertragslaufzeit [Jahre]"
              :property="subscriptionOrder.nr_of_years"
              postfix="Jahre"
            />
            <SubscriptionPropertyLine
              name="Listenpreis (Basispreis * Vertragslaufzeit)"
              prefix="CHF"
              :property="formatFloat(subscriptionOptionsOrder.total_list_price)"
            />

            <div class="pt-5" />

            <SubscriptionPropertyLine
              name="Rabatt aus Abonoment"
              :property="
                formatFloat(subscriptionOptionsOrder.total_discount_percentage)
              "
              postfix="%"
            />
            <SubscriptionPropertyLine
              name="Gesamtpreis nach Abzug Rabatt"
              prefix="CHF"
              :property="
                formatFloat(subscriptionOptionsOrder.total_discount_price)
              "
            />

            <div class="pt-5" />

            <SubscriptionPropertyLine
              name="Vertragslaufzeit [Tage]"
              :property="subscriptionOptionsOrder.total_subscription_duration"
              postfix="Tage"
            />
            <SubscriptionPropertyLine
              name="Verbleibende Laufzeit [Tage]"
              :property="
                subscriptionOptionsOrder.remaining_subscription_duration
              "
              postfix="Tage"
            />
            <SubscriptionPropertyLine
              name="Verbleibende Laufzeit in %"
              :property="
                formatFloat(
                  subscriptionOptionsOrder.remaining_subscription_duration_percentage,
                  4,
                  false
                )
              "
              postfix="%"
            />

            <div class="pt-5" />

            <SubscriptionPropertySummaryLine
              name="Gesamtpreis verbleibende Laufzeit"
              prefix="CHF"
              :property="formatFloat(subscriptionOptionsOrder.total_price)"
            />

            <div class="pt-5" />
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { utilMixin } from "@/mixins/utilMixin";
import { alertMixin } from "@/mixins/alertMixin";
import SubscriptionPropertyLine from "@/components/SubscriptionPropertyLine";
import SubscriptionPropertySummaryLine from "@/components/SubscriptionPropertySummaryLine";
import { mapGetters } from "vuex";
import {
  LOAD_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER,
  LOAD_DASHBOARD_SUBSCRIPTION_ORDER
} from "@/store/dashboard.module";

export default {
  components: { SubscriptionPropertyLine, SubscriptionPropertySummaryLine },
  mixins: [utilMixin, alertMixin],
  name: "SubscriptionOptions",
  data() {
    return {
      subscriptionOptionsOrder: {},
      subscriptionOrder: {},
      subscriptionOrderId: this.$route.params.orderId,
      subscriptionOptionsId: this.$route.params.optionsId,
      isLoaded: false
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      // Load first the pricing before the company is loaded
      await Promise.all([
        this.$store.dispatch(LOAD_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER, {
          orderId: this.subscriptionOrderId,
          optionsId: this.subscriptionOptionsId
        }),
        this.$store.dispatch(LOAD_DASHBOARD_SUBSCRIPTION_ORDER, {
          id: this.subscriptionOrderId
        })
      ]).then(() => {
        this.subscriptionOptionsOrder = this.getDashboardSubscriptionOptionsOrder;
        this.subscriptionOrder = this.getDashboardSubscriptionOrder;
      });
      this.isLoaded = true;
    }
  },
  computed: {
    ...mapGetters([
      "getDashboardSubscriptionOptionsOrder",
      "getDashboardSubscriptionOrder"
    ])
  }
};
</script>
